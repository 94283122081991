@font-face {
  font-family: 'Roboto';
  src: url('./assets/Roboto/Roboto-Regular.ttf');
}

@font-face {
  font-family: 'Roboto Bold';
  src: url('./assets/Roboto/Roboto-Bold.ttf');
}

@font-face {
  font-family: 'Roboto Light';
  src: url('./assets/Roboto/Roboto-Light.ttf');
}

body {
  margin: 0;
  font-family: "Roboto", Arial, Helvetica, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.cursor-pointer {
  cursor: pointer;
}


.text-justified {
  text-align: justify;
}
a {
  text-decoration: none;
  color: inherit !important;
}
.details_div {
  max-height: 300px;
  overflow-y: auto;
}
.staff_details_table {
  margin-top: 133px;
}
.h-350 {
  min-height: 350px;
}
.iframe {
  height: 900px !important;
}
@media (max-width: 820px) {
  .card-responsive {
    width: 75% !important;
  }
  .input-fields {
    width: 100% !important;
  }
  .w-sm-100 {
    width: 100% !important;
  }
}


@media screen and (max-width: 768px) {
  .card-responsive {
    width: 100% !important;
  }
  .input-fields {
    width: 100% !important;
  }
  .w-sm-100 {
    width: 100% !important;
  }
}

@media screen and (max-width: 600px) {
  .w-sm-50 {
    width: 50% !important;
  }

  .w-sm-75 {
    width: 75% !important;
  }
  
  
}
.print-table .ant-table-cell {
  border-left: solid 0.2px #7e7e7e !important;
  border-bottom: solid 0.2px #7e7e7e !important;
}

.print-table .ant-table-thead .ant-table-cell {
  border-top: solid 0.2px #7e7e7e !important;
}
.print-table .ant-table-cell:last-child {
  border-right: solid 0.2px #7e7e7e !important;
}

.print-table .ant-table {
  font-size: 12px !important;
}

.print-table .ant-table-tbody > tr > td {
  padding: 2px !important;
}

.print-table .ant-table-thead > tr > th {
  padding: 3px !important;
}
